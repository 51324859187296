/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { useEffect, useRef, useState } from "react";
import { useMaterialUIController } from "context";

// next
import dynamic from "next/dynamic";
import Head from "next/head";
import Image from "next/image";
import Script from "next/script";

// Pro
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import breakpoints from "assets/theme/base/breakpoints";

// images
import electric from "assets/img/electric.jpg"; // https://unsplash.com/photos/uzEPOK00UAg

// fonts
import { robotoSlab } from "utils/fonts";

// MUI
const Card = dynamic(() => import("@mui/material/Card"), {
  loading: () => null,
});
const Container = dynamic(() => import("@mui/material/Container"), {
  loading: () => null,
});
const Divider = dynamic(() => import("@mui/material/Divider"), {
  loading: () => null,
});
const Grid = dynamic(() => import("@mui/material/Grid"), {
  loading: () => null,
});

// Pro
const MDSnackbar = dynamic(() => import("components/MDSnackbar"), {
  loading: () => null,
});
// const MDBadge = dynamic(() => import('components/MDBadge'), { loading: () => null });

// Theme Parts
const PageLayout = dynamic(
  () => import("examples/LayoutContainers/PageLayout"),
  { loading: () => null }
);
const DefaultNavbar = dynamic(() => import("examples/DefaultNavbar"), {
  loading: () => null,
});
const DefaultFooter = dynamic(() => import("examples/Footers/DefaultFooter"), {
  loading: () => null,
});

// Sections
const Skills = dynamic(() => import("pageSections/Home/Skills"), {
  loading: () => null,
});
const Reviews = dynamic(() => import("pageSections/Home/Reviews"), {
  loading: () => null,
});
const ReviewsSlider = dynamic(() => import("pageSections/Home/ReviewsSlider"), {
  loading: () => null,
});
const Contact = dynamic(() => import("pageSections/Home/Contact"), {
  loading: () => null,
});
const Work = dynamic(() => import("pageSections/Home/Work"), {
  loading: () => null,
});
const Thanks = dynamic(() => import("pageSections/Home/Thanks"), {
  loading: () => null,
});

// Parts
const DefaultCounterCard = dynamic(
  () => import("examples/DefaultCounterCard"),
  { loading: () => null }
);

function Home() {
  const headerRef = useRef(null);
  const [controller] = useMaterialUIController();
  const { sidenavColor, darkMode } = controller;
  const [smallView, setSmallView] = useState(false);
  // process
  const [snackbar, setSnackbar] = useState({
    title: null,
    content: null,
    color: null,
  });

  useEffect(() => {
    let parallax = null;
    const start = async () => {
      if (headerRef.current !== null) {
        // eslint-disable-next-line no-return-assign, new-cap
        await import("rellax").then(
          (u) => (parallax = new u.default(headerRef.current, { speed: -6 }))
        );
      }
    };
    start();
    return () => {
      if (parallax !== undefined && parallax !== null) parallax.destroy();
    };
  }, [headerRef.current]);

  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.sm) setSmallView(true);
      else setSmallView(false);
    }
    window.addEventListener("resize", displayMobileNavbar);
    displayMobileNavbar();
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <>
      <Head>
        <title>CodeFox</title>
        <meta
          name="description"
          content="CodeFox is a full stack developer in Huntington, NY. He works on teams to bring visions to reality."
        />
        <meta property="og:title" content="Checkout CodeFox" />
        <meta
          property="og:description"
          content="A Full Stack Developer in Huntington, NY"
        />
        <meta
          property="og:image"
          content="https://codefox.com/images/codefox.jpg"
        />
        <meta name="twitter:card" content="photo" />
        <meta name="twitter:title" content="CodeFox" />
        <meta
          name="twitter:description"
          content="Full Stack Developer in Huntington, NY"
        />
        <meta
          name="twitter:image"
          content="https://codefox.com/images/codefox.jpg"
        />
        <meta name="twitter:url" content="https://codefox.com" />
      </Head>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
        `}
      </Script>
      <PageLayout>
        <DefaultNavbar transparent light />
        <MDBox
          ref={headerRef}
          minHeight="75vh"
          width="100%"
          sx={{
            background: "#000",
            display: "grid",
            placeItems: "center",
            overflow: "hidden",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={7}
              justifyContent="center"
              mx="auto"
            >
              <MDTypography
                variant="h1"
                color="white"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
                className={robotoSlab.className}
              >
                Full Stack Developer
                {/*{' '}
                <MDBadge
                  badgeContent={<span style={{ color: '#000' }}>pro</span>}
                  size="sm"
                  variant="contained"
                  color="success"
                  container
                  sx={{ mt: -4 }}
              />*/}
              </MDTypography>
              <MDTypography
                variant="body1"
                color="white"
                textAlign="center"
                px={6}
                mt={1}
              >
                Hi I'm CodeFox. I build modern web apps and websites.
              </MDTypography>
              <MDBox
                sx={{
                  position: "absolute",
                  bottom: smallView ? -50 : -450,
                  zIndex: -1,
                }}
              >
                <Image
                  priority
                  src={electric.src}
                  alt="CodeFox"
                  width={smallView ? 500 : 800}
                  height={smallView ? 333 : 533}
                  placeholder="empty"
                />
              </MDBox>
            </Grid>
          </Container>
        </MDBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({
              palette: { white, dark },
              functions: { rgba },
            }) => rgba(darkMode ? dark.main : white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          {/* Counters Start */}

          <MDBox component="section" py={3}>
            <Container>
              <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
                <Grid item xs={12} md={3}>
                  <DefaultCounterCard
                    color={sidenavColor}
                    count={20}
                    suffix="+"
                    title="Years Experience"
                    description="designing and developing web apps and websites."
                  />
                </Grid>
                <Grid item xs={12} md={3} display="flex">
                  <Divider
                    orientation="vertical"
                    sx={{ display: { xs: "none", md: "block" }, mx: 0 }}
                  />
                  <DefaultCounterCard
                    color={sidenavColor}
                    count={20}
                    suffix="+"
                    title="Team members"
                    description="working in Agile product developmment practices."
                  />
                </Grid>
                <Grid item xs={12} md={3} display="flex">
                  <Divider
                    orientation="vertical"
                    sx={{ display: { xs: "none", md: "block" }, mx: 0 }}
                  />
                  <DefaultCounterCard
                    color={sidenavColor}
                    count={1900}
                    suffix="+"
                    title="Commits"
                    description="to repositories across projects in the last year."
                  />
                  <Divider
                    orientation="vertical"
                    sx={{ display: { xs: "none", md: "block" }, ml: 0 }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DefaultCounterCard
                    color={sidenavColor}
                    count={200}
                    suffix="+"
                    title="Endpoints"
                    description="in a recent project for data, files and payments."
                  />
                </Grid>
              </Grid>
            </Container>
          </MDBox>

          <MDBox id="skills" component="section" py={6} my={6}>
            <Skills />
          </MDBox>
          <MDBox id="reviews" component="section" py={12}>
            <Reviews />
          </MDBox>
          <ReviewsSlider />
          <MDBox component="section" py={{ xs: 0, lg: 7 }}>
            <Work />
          </MDBox>
          <MDBox id="contact" component="section" py={{ xs: 0, lg: 6 }}>
            <Contact setSnackbar={setSnackbar} />
          </MDBox>
          <Thanks />
        </Card>
        <MDBox pt={6} px={1} mt={6}>
          <DefaultFooter />
        </MDBox>
      </PageLayout>
      {snackbar.title !== null && (
        <MDSnackbar
          title={snackbar.title || ""}
          content={snackbar.content || ""}
          color={snackbar.color || "dark"}
          open
          close={() => setSnackbar({ title: null, content: null, color: null })}
        />
      )}
    </>
  );
}

export default Home;
